@import "variables.scss";

.App {
  text-align: left;
  @include body-copy;
  background-color: $background-primary;
  @media (max-width: 820px) {
    height: 2340px;
  }
}

.sidebarToggle {
  background-color: white;
  border: 1px solid $button-black;
  border-width: 1px 1px 1px 0;
  display: none;
  align-items: center; // Centers items vertically within the button
  @media (max-width: 820px) {
    display: flex; // Uses flexbox for aligning image and text
  }
  .icon {
    width: 10px; // Set the width of the icon
    height: auto; // Maintain aspect ratio
    margin-right: 10px; // Adds space between the icon and the text
  }
  &:hover {
    text-decoration: underline;
  }
}

.sidebar {
  background-color: $background-primary;
  min-height: 100vh;
  // max-height: 1024px;

  height: 100vh;
  max-width: 320px;
  width: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  z-index: 1;
  @media (max-width: 820px) {
    position: absolute;
    z-index: 3;
    display: none;
    &.active {
      display: block;
    }
    .unswMotif {
      overflow: hidden;
      width: 100%;
    }
  }
  .unswMotif {
    width: 100%;
  }
  .branding {
    margin-top: auto;
    position: sticky;
    bottom: 0px;
    height: 140px;
    // background: url("../public/unsw-hats-logo.png") no-repeat;
    background-color: $background-primary;
    // background-size: 70%;
    // background-position: 20px 20px;
    // box-shadow: -4px 4px 6px 0px rgba(0, 0, 0, 0.5);
    border-top: 1px solid #1a1e2b;
    padding: 15px 20px;
    img {
      // height: 60px;
      margin: 0 auto;
      &:first-child {
        margin-bottom: 10px;
      }
      width: 90%;
    }
  }
}

.contentSidebar {
  width: 312px;
  height: 100vh;
  @media (max-width: 820px) {
    top: 70vh;
    width: 100%;
    right: 0;
    height: 100%;
  }
}

.main {
  display: flex;
  height: calc(100vh - 220px);
  width: 100%;
}

.content {
  display: flex;
  flex-grow: 1;
}

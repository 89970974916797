$background-primary: #f9f9f9;
$background-blue: #e2e7f6;
$background-pink: #ffe8e7;

$usnw-yellow: #ffdc00;
$button-black: #1a1e2b;
$button-alt: #607a8d;

@mixin body-copy {
  font-family: "Roboto Mono", monospace;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

@mixin heading-sans-serif {
  font-family: "Arial", sans-serif;
  font-weight: 900;
  color: #000;
}

@mixin legend-marker {
  position: relative; // Ensure that the pseudo-element can be positioned absolutely within it.
  padding-left: 18px;
  list-style: none;
  &::before {
    content: ""; // Necessary to make the pseudo-element appear.
    position: absolute;
    left: 0; // Position the circle to the left of the content. Adjust as necessary.
    top: 50%; // Vertically center the circle relative to the container.
    transform: translateY(-50%); // Center align the circle vertically.
    width: 10px; // Size of the circle. Adjust based on your design requirements.
    height: 10px; // Height should be the same as the width for a perfect circle.
    margin-right: 10px;
    display: inline-block;
    border: 1px solid #000;
  }

  // Affordability

  &.buy-affordable {
    &::before {
      background-color: #849dea;
    }
  }
  &.buy-moderately-unaffordable {
    &::before {
      background-color: #ffbebb;
    }
  }
  &.buy-seriously-unaffordable {
    &::before {
      background-color: #a30700;
    }
  }
  &.buy-severely-unaffordable {
    &::before {
      background-color: #67000d;
    }
  }

  &.rent-affordable {
    &::before {
      background-color: #849dea;
    }
  }
  &.rent-acceptable {
    &::before {
      background-color: #e2e7f6;
    }
  }
  &.rent-moderately-unaffordable {
    &::before {
      background-color: #ffbebb;
    }
  }
  &.rent-unaffordable {
    &::before {
      background-color: #e05151;
    }
  }
  &.rent-severely-unaffordable {
    &::before {
      background-color: #a30700;
    }
  }
  &.rent-extremely-unaffordable {
    &::before {
      background-color: #67000d;
    }
  }

  // Shortage
  &.much-lower {
    &::before {
      background-color: #a7a7a7;
      border-radius: 50%; // This makes the shape a circle.
    }
  }
  &.lower {
    &::before {
      background-color: #c5c5c5;
      border-radius: 50%; // This makes the shape a circle.
    }
  }
  &.normal {
    &::before {
      background-color: #efefef;
      border-radius: 50%; // This makes the shape a circle.
    }
  }
  &.higher {
    &::before {
      background-color: #ff9fa1;
      border-radius: 50%; // This makes the shape a circle.
    }
  }
  &.much-higher {
    &::before {
      background-color: #ff484b;
      border-radius: 50%; // This makes the shape a circle.
    }
  }
}

@import "../../variables";

.mapWrapper {
  position: relative;
  flex: 3; // Takes two thirds of the space
  height: 100%; // Fill the container height
}

.loadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8); /* semi-transparent white */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; /* Ensures the overlay is above the map but below other UI elements */
  @media (max-width: 820px) {
    align-items: baseline;
    top: 40%;
  }
}

.Map {
  // height: 100vh; // You can adjust the height
  // width: 100%;
  // flex: 2; // Takes two thirds of the space
  width: 100%;
  height: 100%; // Fill the container height
  @media (max-width: 820px) {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 70vh;
  }
}

.tooltip {
  // position: absolute;
  // background-color: white;
  // padding: 10px;
  // border-radius: 3px;
  // border: 1px solid #ddd;
  // display: none;
  h4 {
  }
  h5 {
    font-weight: bold;
  }
  .marker {
    @include legend-marker;
    display: list-item;
    line-height: 1.1em;
    margin-bottom: 5px;
  }
}

/* Class to show the tooltip */
.visible {
  display: block;
}

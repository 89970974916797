@tailwind base;
@tailwind components;
@tailwind utilities;
@import "variables.scss";

html {
  overflow-x: hidden;
}

h1,
h2,
h3,
h4 {
  @include heading-sans-serif;
}

.rc-slider-rail,
.rc-slider-track {
  background-color: $button-black !important; /* Sets the rail color to black */
  height: 2px !important;
}

.rc-slider-dot {
  bottom: -1px;
  border-color: $button-black !important;
}

.rc-slider-dot-active {
  border-color: $button-black !important;
}

.rc-slider-handle {
  border-color: $button-black !important;
  margin-top: -6px !important;
  background-color: $usnw-yellow !important;
  opacity: 1 !important;
}

.rc-slider-handle:focus-visible {
  box-shadow: 0 0 0 3px $button-black !important;
}
